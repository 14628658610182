.chart-container{
    width: 70%;
}
.charts{
    display: flex;
    justify-content: flex-start;
}
.info-container{
    display: flex;
    justify-content:start;
    flex-wrap: wrap;
}
.statistic-chart{
    margin: 1.5%;
}
.switch{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.operations:hover{
    color: black;
    cursor: pointer;
}