.image{
    margin-right: 1em;
    margin-left: 0.3em;
    width: 30px;
    height: 30px;
}
.pContainer{
    font-size: 17px;
    margin-top: 0.2em;
}
.generalContainer{
    display: flex;
}