.listSettings{
    justify-content:space-around;
    width: auto;
    height: auto;
}
.setting{
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 7px;
    background-color:blue;
    border-radius: 10px;
    border:0;
    outline: none;
    padding: 15px;
    cursor: pointer !important;
}
.setting h5{
    margin: 0;
}
.setting img{
    margin-right: 10px;
}
