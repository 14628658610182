.filter{
    @media screen and (min-width: 1200px){
        flex: 0 0 50%;
        max-width: 50%;
    }

    @media screen and (min-width: 1500px){
        flex: 0 0 32%;
        max-width: 32%;
    }

    @media screen and (min-width: 1600px){
        flex: 0 0 30%;
        max-width: 30%;
    }

    @media screen and (min-width: 1800px){
        flex: 0 0 25%;
        max-width: 25%;
    }
}