.cardAction {
    position: relative;
    left: 300px;
    bottom: 125px;
    border: none;
    background-color: white;
    border-radius: 15px;
    height: 45px;
    width: 200px;
    padding: 5px;
    box-shadow: 0 0 5px 2px gray;
    outline: none;
}

.expiredDate{
    position: relative;
    left: 110px;
    bottom: 30px; 
    font-size: 12px;
}
.pan{
    position: relative;
    left: 20px;
    bottom: 125px;
    font-size: 20px;
}
